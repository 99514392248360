import React from 'react'
import logo from '../assets/images/wappen.png'
import Link from "gatsby-link";

const Header = (props) => (
    <header id="header">
        <Link to="/">
            <img src={logo} alt="" className="logo"/>
        </Link>
            <div className="brand">
                <Link to="/">
                    Christian Weber
                </Link>
            </div>
        <nav>
            <Link to="#contact">KONTAKT</Link>
        </nav>
    </header>

)

export default Header
