import React from 'react'
import { Link } from 'gatsby'

const Footer = (props) => (
    <footer id="footer">
        <div className="inner">
           <ul className="copyright">
                <li>&copy; Sicherheit & Service</li>
                <li><Link href="/impressum">Impressum</Link></li>
                <li><a href="/datenschutz">Datenschutzerklärung</a></li>
            </ul>
        </div>
    </footer>
)

export default Footer
