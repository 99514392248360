import React from 'react'

const Contact = (props) => (
    <section id="contact">
        <div className="inner">
            <section>
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-envelope"></span>
                        <h3>Email</h3>
                        <a href="mailto:info@s-u-s.de">info@s-u-s.de</a>
                    </div>
                </section>
                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-phone"></span>
                        <h3>Telefon</h3>
                        <a href="tel:+49 160 7656275">+49 160 7656275</a>
                    </div>
                </section>
            </section>
        </div>
    </section>
)

export default Contact
