import React from 'react'

import '../assets/scss/main.scss'
import Header from './Header'
import Contact from './Contact'
import Footer from './Footer'
import Helmet from "react-helmet";
import { graphql } from "gatsby";

class Layout extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: 'is-loading'
        }
    }

    componentDidMount () {
        this.timeoutId = setTimeout(() => {
            this.setState({loading: ''});
        }, 100);
    }

    componentWillUnmount () {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }

    render() {
        const { children } = this.props

        return (
            <div className={`body ${this.state.loading}`}>
                <div id="wrapper">
                    <Helmet
                        title="Christian Weber"
                        meta={[
                        { name: 'description', content: 'Sample' },
                        { name: 'keywords', content: 'sample, something' },
                        ]}
                    >
                    </Helmet>
                    <Header />
                    {children}
                    <Contact />
                    <Footer />
                </div>
            </div>
        )
    }
}

export const query = graphql`
  {
    title: sanitySettings(key: {eq: "Title"}) {
      value
    }
    description: sanitySettings(key: {eq: "Description"}) {
      value
    }
    keywords: sanitySettings(key: {eq: "Keywords"}) {
      value
    }
  }
`

export default Layout
